$(document).ready(function(){
  if(window.location.href.includes('login_error')) {
    var login_error_detail;
    if (queryStringParams["login_error"] == 1) {
      login_error_detail = 'Anda salah memasukkan email atau kata sandi.';
    } else if (queryStringParams["login_error"] == 2) {
      login_error_detail = 'Account Anda telah diblokir.'
    } else if (queryStringParams["login_error"] == 3) {
      login_error_detail = 'Silahkan <i>login</i> menggunakan metode lainnya atau coba lagi dalam beberapa saat.'
    } else {
      login_error_detail = 'Anda salah memasukkan email atau kata sandi.';
    }
    $('#profile-update-message').html(login_error_detail).removeClass('success').addClass('failure').fadeIn('fast').delay( 3000 ).slideUp( 400 );
    $('.notify-bar').addClass('set-height-40');
    $('#top-content-space').addClass('set-height-140');
    setTimeout(function(){ $('.notify-bar').removeClass('set-height-40');$('#top-content-space').removeClass('set-height-140'); }, 3500);
    customEventFormSubmission(login_error_detail);
  }
  if(queryStringParams["page"]){
    var hash = location.hash;
    $('#current_page').val(decodeURIComponent(queryStringParams["page"])+hash)
  }
});
$(window).on('load', function(){
  sendLotameData();
})
var contentHeight,spaceAvailable
var setContentPosition = function() {
  contentHeight = $('.login-landing-inner').outerHeight();
  spaceAvailable = $(window).height();
  if(spaceAvailable-60 > contentHeight) {
    $('.login-landing-inner').css('top', (spaceAvailable-50 - contentHeight)/2)
  }
}
var setContainerHeight = function() {
  if(contentHeight > (spaceAvailable-60)) {
  } else {
    $('.content').height($(window).height()-50)
  }
}
$(window).resize(function(){
  //setContentPosition();
  //setContainerHeight()
})
//setContentPosition();
//setContainerHeight()

$('#open-forgotpass').click(function(event) {
    $('#forgot-pass').parent().removeClass('collapse');
});

$('.form input').focusout(function() {
  var iconProfile = $(this).parent().find('.register-data-icon').attr("src")
  $(this).parent().css('border-bottom-color','#CCCCCC')
}).focusin(function() {
  var iconProfile = $(this).parent().find('.register-data-icon').attr("src")
  $(this).parent().css('border-bottom-color','#2ADBAB')
})

function sendLotameData(){
      var _cc14390_Add = [];
          _cc14390_Add.push({'type' : 'seg', 'value' : 'historia.id : Login Event'});
          addLotame(_cc14390_Add)
}

function customEventFormSubmission(additionaldetail){
  dataLayer.push({
    'event': 'formsubmit',
    'formname': 'User Login',
    'formstep': 'Step 1',
    'additionaldetail': additionaldetail
  });
}

$('#login-landing').submit(function(){
  userCustomEvent('Email')
})

$('#login-form-tw-main').submit(function(){
  userCustomEvent('Twitter')
})
$('#twitter-button').click(function(){
  userCustomEvent('Twitter')
})
$('#gmail-button').click(function(){
  userCustomEvent('Gmail')
})
$('#facebook-button').click(function(){
  userCustomEvent('Facebook')
})

function userCustomEvent(medium) {
  dataLayer.push({
    'event': 'usersession',
    'sessionactivity': 'Login',
    'sessionmedium': medium
  });
}